* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.nowrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
h1 {
  font-size: 36px;
}
h3,
h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
a {
  color: var(--active-color);
  text-decoration: none;
}
:root {
  --series-count-background-color: #678696;
  --active-color: #20a5d6;
}
.thumbnail.active .alt-image-text {
  border-color: var(--active-color);
  box-shadow: none;
  transition: var(--sidebar-transition);
}
.study-details {
  align-items: stretch;
  color: var(--text-primary-color);
  box-shadow: inset 0 0 0 1px var(--ui-border-color-dark);
  border: 2px solid transparent;
  border-radius: 12px;
  min-height: 55px;
  margin: 0 5px 10px 5px;
  padding: 5px;
  position: relative;
  display: flex;
}
.study-details.active {
  background-color: var(--ui-gray-dark);
}
.study-details .study-modality {
  background-color: var(--active-color);
  color: #000;
  font-size: 18px;
  border: 2px solid transparent;
  border-radius: 6px;
  min-width: 45px;
  min-height: 40px;
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.study-details .study-date {
  color: var(--active-color);
}
.study-details .study-description {
  word-break: break-all;
}
.thumbnail {
  cursor: pointer;
  display: flex;
  margin: 0 auto;
  min-width: 90%;
}
.thumbnail .alt-image-text {
  align-items: center;
  justify-content: center;
  background-color: var(--primary-background-color);
  box-shadow: inset 0 0 0 1px var(--ui-border-color-dark);
  border: 2px solid transparent;
  border-radius: 12px;
  min-height: 135px;
  max-height: 250px;
  margin: 0 auto;
  padding: 5px;
  position: relative;
  transition: var(--sidebar-transition);
  width: 130px;
  display: flex;
}
.thumbnail .alt-image-text h1 {
  text-align: center;
  color: var(--text-primary-color);
  max-width: 200px;
  max-height: 250px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
.thumbnail .series-details {
  display: flex;
  flex-direction: column;
  color: var(--text-primary-color);
  font-size: 14px;
  line-height: 1.3em;
  margin-top: 5px;
  margin-left: 1rem;
  margin-right: 0.5rem;
  max-width: 217px;
  position: relative;
  word-wrap: break-word;
}
.thumbnail .series-details .series-description {
  flex-grow: 1;
}
.thumbnail .series-details.info-only .series-description {
  display: none;
}
.thumbnail .series-information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.thumbnail .series-information .item-frames .icon {
  height: 18px;
}
.thumbnail .series-information .item-series.image-frames .icon {
  background-color: var(--series-count-background-color);
  margin-top: 6px;
  position: relative;
}
.thumbnail .series-information .item-series.image-frames .icon:after {
  content: '';
  background-color: var(--active-color);
  box-shadow: 1px 1px rgba(0,0,0,0.115);
  left: -4px;
  position: absolute;
  top: -4px;
}
.thumbnail .series-information .item-series.image-frames .icon,
.thumbnail .series-information .item-series.image-frames .icon:after {
  content: '';
  border: 0px solid var(--series-count-background-color);
  display: inline-block;
  height: 11px;
  width: 11px;
}
.thumbnail .series-information .warning {
  margin: auto 0;
  opacity: 1;
  color: #e29e4a;
}
.thumbnail .series-information .warning svg {
  width: 16px;
  height: 14px;
  pointer-events: inherit;
}
.thumbnail .series-information .value {
  color: var(--text-secondary-color);
  display: inline-block;
  font-size: 12px;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 15px);
  vertical-align: middle;
}
.thumbnail .series-information .icon {
  color: var(--active-color);
  display: inline-block;
  font-size: 10px;
  font-weight: 900;
  text-align: right;
  width: 11px;
}
